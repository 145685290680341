import React from 'react';
import { UserFeatures } from '../../../contentful/content-types/personalization';
import { ButtonCTA } from '../Button';
import { Col, Container, Div, Input, Label, Row } from '../Elements';
import Text from '../Text/Text';

type FactField = {
  [x in keyof UserFeatures]: {
    label: string;
    type: 'text' | 'checkbox' | 'number';
    notFormElement?: boolean;
  };
};

const factFields: FactField = {
  is_customer: {
    label: 'Is customer',
    type: 'checkbox',
  },
  bot_management_enabled: {
    label: 'Bot Management enabled',
    type: 'checkbox',
  },
  argo_allow_smart_routing: {
    label: 'Argo allow smart routing',
    type: 'checkbox',
  },
  has_free_zone: {
    label: 'Has Free Zone',
    type: 'checkbox',
  },
  has_pro_zone: {
    label: 'Has Pro Zone',
    type: 'checkbox',
  },
  has_biz_zone: {
    label: 'Has Biz Zone',
    type: 'checkbox',
  },
  has_ent_zone: {
    label: 'Has Ent Zone',
    type: 'checkbox',
  },
  ctm_enabled: {
    label: 'CTM Enabled',
    type: 'checkbox',
  },
  workers_enabled: {
    label: 'Workers Enabled',
    type: 'checkbox',
  },
  audit_logs_enabled: {
    label: 'Audit Logs Enabled',
    type: 'checkbox',
  },
  cf_registrar_enabled: {
    label: 'CF Registrar Enabled',
    type: 'checkbox',
  },
  proxy_anything_enabled: {
    label: 'Proxy Anything Zone',
    type: 'checkbox',
  },
  access_enabled: {
    label: 'Access Enabled',
    type: 'checkbox',
  },
  rate_limiting_enabled: {
    label: 'Rate limiting Enabled',
    type: 'checkbox',
  },
  stream_enabled: {
    label: 'Stream Enabled',
    type: 'checkbox',
  },
  logshare_enabled: {
    label: 'Logshare Enabled',
    type: 'checkbox',
  },
  organization_name: {
    label: 'Organization Name',
    type: 'text',
  },
  pct_avg_change: {
    label: 'Percentage Average Change',
    type: 'number',
  },
  argo_avg: {
    label: 'Argo Average',
    type: 'number',
  },
  no_argo_avg: {
    label: 'No Argo Average Average',
    type: 'number',
  },
};

interface PreviewProps {
  previewData: UserFeatures | null;
  isPreview: boolean;
  setIsPreview: React.Dispatch<React.SetStateAction<boolean>>;
  setPreviewData: React.Dispatch<React.SetStateAction<UserFeatures | null>>;
}

const Preview: React.FC<PreviewProps> = ({
  previewData,
  setPreviewData,
  isPreview,
  setIsPreview,
}) => {
  const hadleChange = (e: any, isCheckbox: boolean) => {
    setPreviewData({
      ...((previewData as any) || {}),
      [e.target.name as any]: isCheckbox ? e.target.checked : e.target.value,
    });
  };

  return (
    <Container>
      <Div
        border="all"
        borderColor="orange1"
        display="flex"
        alignItems="center"
        justifyContent="between"
      >
        <Text paddingLeft={2} variant="headline5">
          Preview Personalizer component
        </Text>
        <ButtonCTA onClick={() => setIsPreview(!isPreview)} buttonColor="orange" buttonStyle="wide">
          {isPreview ? 'Use worker data' : 'Use preview data'}
        </ButtonCTA>
      </Div>
      {isPreview && (
        <Div border="all" borderColor="gray3" padding={4}>
          <Text variant="headline5" marginBottom={2}>
            Select Mock user config
          </Text>
          <Row>
            {Object.keys(factFields).map(fact => {
              const isCheckBox = (factFields as any)[fact].type === 'checkbox';
              return (
                <Col marginBottom={1} key={fact} lg={3}>
                  <Label display="flex" flexWrap="wrap-reverse">
                    <Input
                      name={fact}
                      fontSize={2}
                      onChange={e => hadleChange(e, isCheckBox)}
                      padding={isCheckBox ? 0 : 1}
                      width={isCheckBox ? 'auto' : '100%'}
                      placeholder={(factFields as any)[fact].label}
                      marginRight={1}
                      type={(factFields as any)[fact].type}
                    />
                    <Text tag="Span" variant="body2-bold">
                      {(factFields as any)[fact].label}
                    </Text>
                  </Label>
                </Col>
              );
            })}
          </Row>
        </Div>
      )}
    </Container>
  );
};

export default Preview;
